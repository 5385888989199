import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@/js/bootstrap';
import '@/js/helpers/themeSwitcherClick';
import '@/js/helpers/setDynamicVH';

import GlobalSearch from '@/js/global/menu/GlobalSearch.vue';
import FiatCurrencySearch from '@/js/global/top-menu/FiatCurrencySearch.vue';
import CurrencyLanguageSearch from '@/js/global/top-menu/CurrencyLanguageSearch.vue';
import GasPriceTooltip from '@/js/global/top-menu/GasPriceTooltip.vue';
import { vFocus } from '@/js/directives/vFocus';

const Translate = defineAsyncComponent(() => import('@/js/helpers/Translate.vue'));
const LatestNewsAndVideos = defineAsyncComponent(() => import('@/js/global/widgets/LatestNewsAndVideos.vue'));
const CompanionBubble = defineAsyncComponent(() => import('@/js/global/top-menu/CompanionBubble.vue'));
const MarketingCampaignComponents = defineAsyncComponent(() => import('@/js/marketing-campaigns/MarketingCampaignComponents.vue'));
const ConnectModal = defineAsyncComponent(() => import('@/js/global/menu/ConnectModal.vue'));
const MobileMenuToggleButton = defineAsyncComponent(() => import('@/js/global/buttons/MobileMenuToggleButton.vue'));
const PromoNotifications = defineAsyncComponent(() => import('@/js/global/top-menu/PromoNotifications.vue'));
const StoreMenuData = defineAsyncComponent(() => import('@/js/global/menu/partials/StoreMenuData.vue'));
const MainMenu = defineAsyncComponent(() => import('@/js/global/menu/MainMenu.vue'));
const SubscribeNewsletterFooterForm = defineAsyncComponent(() => import('@/js/subscribe/SubscribeNewsletterFooterForm.vue'));
const BaseTippy = defineAsyncComponent(() => import('@/js/components/Tippy/BaseTippy.vue'));
const AppPopup = defineAsyncComponent(() => import('@/js/global/top-menu/AppPopup.vue'));

const app = createApp({});

app.directive('focus', vFocus);

app.use(createPinia());

app.use(VueSplide);
app.use(VueToast);

// Helpers
app.component('Translate', Translate);
app.config.globalProperties.Translate = Translate.methods;

// Global
app.component('StoreMenuData', StoreMenuData);
app.component('MainMenu', MainMenu);
app.component('MobileMenuToggleButton', MobileMenuToggleButton);
app.component('GlobalSearch', GlobalSearch);
app.component('FiatCurrencySearch', FiatCurrencySearch);
app.component('CurrencyLanguageSearch', CurrencyLanguageSearch);
app.component('PromoNotifications', PromoNotifications);
app.component('GasPriceTooltip', GasPriceTooltip);
app.component('ConnectModal', ConnectModal);
app.component('LatestNewsAndVideos', LatestNewsAndVideos);
app.component('CompanionBubble', CompanionBubble);
app.component('MarketingCampaignComponents', MarketingCampaignComponents);
app.component('SubscribeNewsletterFooterForm', SubscribeNewsletterFooterForm);
app.component('BaseTippy', BaseTippy);
app.component('AppPopup', AppPopup);

const mountApp = () => {
    app.mount('#app-mount');
};

export { mountApp, app };
