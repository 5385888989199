import { defineStore } from 'pinia';
import {
    computed, ref, watch,
} from 'vue';
import { NotificationType } from '../../quests/dashboard/types/types';
import { getTrans } from '../../helpers/getTrans';
import { asset_cdn, route } from '../../helpers/helpers';
import { useDeviceSize } from '../../composables/useDeviceSize';
import { INavigationItem, TMenu, TMenuItemHeight } from '../../global/menu/types/menuTypes';
import { IMenuFooter } from '../../global/menu/types/menuFooterTypes';
import { MenuTypeEnum } from '../../global/menu/enums/menuEnums';

const PROMO_NOTIFICATIONS: NotificationType[] = [
    {
        title: getTrans('header.promoNotificationTitle1', 'Claim Free NFT Certificate'),
        subtitle: getTrans('header.promoNotificationSubtitle1', 'Complete the official BitDegree Web3 Exam & get rewarded!'),
        link: route('redirect-to-mission'),
        img: asset_cdn('crypto-prices/assets/menu/icons/top-crypto-exchanges.png'),
    },
    {
        title: getTrans('header.promoNotificationTitle2', 'Most Secure Wallets To Start With'),
        subtitle: getTrans('header.promoNotificationSubtitle2', 'Check the analysis report on Top-rated Crypto Wallets that are safest for your funds.'),
        link: 'https://www.bitdegree.org/crypto/best-cryptocurrency-wallet',
        img: asset_cdn('crypto-prices/assets/menu/icons/best-crypto-wallets.png'),
    },
    {
        title: getTrans('header.promoNotificationTitle3', 'Exclusive Crypto Deal List of 2024'),
        subtitle: getTrans('header.promoNotificationSubtitle3', 'Get huge savings on the biggest crypto brands with custom discounts & deals.'),
        link: route('crypto.coupons-index'),
        img: asset_cdn('crypto-prices/assets/menu/icons/best-crypto-deals.png'),
    },
    {
        title: getTrans('header.promoNotificationTitle4', 'Latest Lucky Draw Rewards Distributed'),
        subtitle: getTrans('header.promoNotificationSubtitle4', 'New Lucky Draw winners have just been rewarded. Join the next one!'),
        link: route('redirect-to-mission'),
        img: asset_cdn('crypto-prices/assets/menu/icons/crypto-rewards.png'),
    },
];

export const useMenuStore = defineStore('MenuStore', () => {
    let initialMenu: TMenu | null = null;
    const menuData = ref<TMenu | null>(null);
    const mobileMenuFooterData = ref<IMenuFooter | null>(null);
    const promoNotifications = ref(PROMO_NOTIFICATIONS);
    const isNotificationsModalOpen = ref(false);
    const isCurrencySearchModalOpen = ref(false);
    const isMobileNavOpen = ref(false);
    const menuItemHeight = ref<TMenuItemHeight>('50px');

    const MENU_BREAKPOINT = 1240;

    const { windowWidth } = useDeviceSize();

    const isDesktop = computed(() => windowWidth.value >= MENU_BREAKPOINT);

    const removeActiveClasses = () => {
        const topMenu = document.querySelector('.top-menu');
        const navbar = document.querySelector('.navbar-dark');
        const topMenuPromotionalBar = document.querySelector('.top-menu-promotional-bar');

        if (topMenuPromotionalBar) {
            topMenuPromotionalBar.classList.remove('nav-active');
        }

        if (topMenu) {
            topMenu.classList.remove('nav-active');
        }

        if (navbar) {
            navbar.classList.remove('nav-active');
        }
    };

    const resetMenu = () => {
        isMobileNavOpen.value = false;
        isNotificationsModalOpen.value = false;
        isCurrencySearchModalOpen.value = false;
        removeActiveClasses();
        menuData.value = structuredClone(initialMenu);
    };

    const resetChildren = (menu) => {
        menu.children.forEach((childMenu) => {
            childMenu.isOpen = false;
        });
    };

    const updateMenuData = (data: TMenu) => {
        initialMenu = structuredClone(data);
        menuData.value = structuredClone(data);
    };

    const updateMobileFooterData = (data: IMenuFooter) => {
        mobileMenuFooterData.value = data;
    };

    const openDefaultSubmenu = (menu: TMenu) => {
        const isMenuItem = (menu): menu is INavigationItem => menu.type === MenuTypeEnum.ITEM;
        const hasChildren = (menu): menu is TMenu & { children: TMenu } => menu?.children;

        if (isDesktop.value) {
            if (isMenuItem(menu) && hasChildren(menu)) {
                menu.isOpen = true;
            }

            if (hasChildren(menu)) {
                openDefaultSubmenu(menu?.children[0]);
            }
        }
    };

    watch(() => isDesktop.value, () => resetMenu());
    watch(() => isMobileNavOpen.value, () => (!isMobileNavOpen.value ? resetMenu() : null));

    return {
        initialMenu,
        menuData,
        mobileMenuFooterData,
        promoNotifications,
        isNotificationsModalOpen,
        isCurrencySearchModalOpen,
        isMobileNavOpen,
        menuItemHeight,
        isDesktop,
        updateMenuData,
        updateMobileFooterData,
        resetMenu,
        openDefaultSubmenu,
        resetChildren,
    };
});
