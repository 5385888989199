<template>
    <div class="modal-container">
        <div class="close-button"
             @click="closeModal">
            &times;
        </div>
        <div>
            <SearchInput v-model="search" />
            <div class="content-selector-container">
                <div class="content-selector"
                     :class="{ active: activeTab === TabsEnum.LANGUAGE}"
                     @click="switchTab(TabsEnum.LANGUAGE)">
                    {{ TabsEnum.LANGUAGE }}
                </div>
                <div class="content-selector"
                     :class="{ active: activeTab === TabsEnum.FIAT_CURRENCY}"
                     @click="switchTab(TabsEnum.FIAT_CURRENCY)">
                    {{ TabsEnum.FIAT_CURRENCY }}
                </div>
            </div>
            <div class="list-container">
                <SearchListItem v-for="(listItem, index) in filteredTabData"
                                :key="index"
                                :code="getListItemCode(listItem)"
                                :link="getListItemLink(listItem)"
                                :logo="asset_cdn(listItem.logo)"
                                :name="listItem.name" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useMenuStore } from '../../../../store/menu/MenuStore';
    import { asset_cdn } from '../../../../helpers/helpers';
    import SearchListItem from './SearchListItem.vue';
    import SearchInput from './SearchInput.vue';
    import { getTrans } from '../../../../helpers/getTrans';
    import { IFiatCurrency, ILocales, TLocales } from '../../types/menuFooterTypes';

    const menuStore = useMenuStore();

    const TabsEnum = {
        LANGUAGE: getTrans('header.language', 'Language'),
        FIAT_CURRENCY: getTrans('header.fiatCurrency', 'Fiat Currency'),
    } as const;

    type TTabsEnum = typeof TabsEnum[keyof typeof TabsEnum];

    const activeTab = ref<TTabsEnum>(TabsEnum.LANGUAGE);
    const search = ref('');

    const tabData = computed<TLocales | IFiatCurrency[]>(() => {
        if (activeTab.value === TabsEnum.LANGUAGE) {
            return menuStore.mobileMenuFooterData.locales as TLocales;
        }

        return menuStore.mobileMenuFooterData.fiatCurrencies as IFiatCurrency[];
    });

    const filteredTabData = computed(() => {
        const isLocales = (tabItem: ILocales | IFiatCurrency): tabItem is ILocales => 'code' in tabItem;

        const tabDataValues = Object.values(tabData.value);

        return tabDataValues.filter(
            (tabItem) => {
                const searchLowercase = search.value.toLowerCase();
                const nameLowercase = tabItem.name.toLowerCase();

                if (activeTab.value === TabsEnum.LANGUAGE) {
                    if (isLocales(tabItem)) {
                        const codeLowercase = tabItem.code?.toLowerCase();

                        return nameLowercase.includes(searchLowercase)
                            || codeLowercase.includes(searchLowercase);
                    }
                }

                const currencyCodeLowercase = (tabItem as IFiatCurrency).currency_code.toLowerCase();

                return nameLowercase.includes(searchLowercase)
                    || currencyCodeLowercase.includes(searchLowercase);
            },
        );
    });

    const getListItemCode = (listItem) => listItem.code || `${listItem.currency_code} - ${listItem.sign}`;
    const getListItemLink = (listItem) => menuStore.mobileMenuFooterData.currentRouteUrls[listItem.key] || `?currency=${listItem.currency_code}`;

    const switchTab = (tab) => {
        activeTab.value = tab;
        search.value = '';
    };

    const closeModal = () => {
        menuStore.isCurrencySearchModalOpen = false;
    };
</script>

<style scoped lang="scss">
@import "../../../../../sass/partials/variables";
@import "../../../../../sass/partials/z-index";

.modal-container {
    position: fixed;
    z-index: $navigation-modal-z-index;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    padding: 16px;
    background: $white;

    .close-button {
        width: min-content;
        margin-bottom: 8px;
        color: $steel-blue;
        cursor: pointer;
        font-size: 40px;
        line-height: 40px;
        transition: color 200ms ease;

        &:hover {
            color: $black;
        }
    }

    .content-selector-container {
        display: flex;
        padding: 3px;
        border: 1px solid $purple;
        border-radius: 14px;
        margin-bottom: 8px;
        background: $white;
        font-size: 12px;
        font-weight: 700;
        gap: 4px;

        .content-selector {
            display: flex;
            width: 50%;
            justify-content: center;
            padding: 3px 0;
            border-radius: inherit;
            color: $purple;
            cursor: pointer;
            transition: all 200ms;

            &:hover,
            &.active {
                background: $purple;
                color: $white;
            }
        }
    }

    .list-container {
        max-height: 80vh;
        overflow-y: scroll;
    }
}
</style>
